import React, { useEffect } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import "jquery-mask-plugin";

const FormContato = () => {
  useEffect(() => {
    $("#tel").mask("(00) 00000-0000");
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    const value = $("#form_solicitacao").serializeArray();

    const nome = value[0].value;
    const email = value[1].value;
    const telefone = value[2].value;
    const sobre = value[3].value;

    const enviarMensagem = async () => {
      const webhookUrl =
        "https://discord.com/api/webhooks/1164963924194631800/K1Sy8zuzQ0-pMJ212TfZXbTgKsFWAx8sLh71MuRkkkX_rx_b_EgeB6l2QTFgwrseJmLT";
  
      // const body = {
      //   "username": "Formulário ReactJS",
      //   "avatar_url": "https://cdn.discordapp.com/attachments/1148343796644458566/1164965394998636574/375901556_827196845717339_1041275780904625271_n.jpg?ex=65452192&is=6532ac92&hm=60fd5f61e09ddd832cb7695f62576875d18dcc152d8f02b17a588f8a968fdf62&",
      //   "content": "abc",
      //   "embeds": [
      //     {
      //       "author": {
      //         "name":  `${value[0].value} ${value[2].value}`,
      //         "url": "https://deltalimp.com.br",
      //         "icon_url": "https://i.imgur.com/R66g1Pe.jpg"
      //       },
      //       "title": value[1]?.value,
      //       "url": `mailto:${value[1].value}`,
      //       "description": `${value[3].value}`,
      //       "color": 'red',
      //       "footer": {
      //         "text": "Ti - DeltaLimp :heart:",
      //         "icon_url": "https://cdn.discordapp.com/attachments/1148343796644458566/1164965394998636574/375901556_827196845717339_1041275780904625271_n.jpg?ex=65452192&is=6532ac92&hm=60fd5f61e09ddd832cb7695f62576875d18dcc152d8f02b17a588f8a968fdf62&"
      //       }
      //     }
      //   ]
      // }

      const body = {
        "username": "Formulário ReactJS",
        "avatar_url": "https://cdn.discordapp.com/attachments/1148343796644458566/1164965394998636574/375901556_827196845717339_1041275780904625271_n.jpg?ex=65452192&is=6532ac92&hm=60fd5f61e09ddd832cb7695f62576875d18dcc152d8f02b17a588f8a968fdf62&",
        "content": "",
        "embeds": [
          {
            "author": {
              "name": "Ti - DeltaLimp",
              "url": "https://deltalimp.com.br",
              "icon_url": "https://cdn.discordapp.com/attachments/1148343796644458566/1164965394998636574/375901556_827196845717339_1041275780904625271_n.jpg?ex=65452192&is=6532ac92&hm=60fd5f61e09ddd832cb7695f62576875d18dcc152d8f02b17a588f8a968fdf62&"
            },
            "title": `Novo formulário de ${nome}`,
            "url": "",
            "description": "",
            "color": 15258703,
            "fields": [
              {
                "name": "Nome",
                "value": nome,
                "inline": true
              },
              {
                "name": "Telefone",
                "value": telefone,
                "inline": true
              },
              {
                "name": "Email",
                "value": email,
                "inline": true
              },
              {
                "name": "Messagem",
                "value": sobre
              }
            ],
            "footer": {
              "text": "Ti - Deltalimp ❤️",
              "icon_url": "https://cdn.discordapp.com/attachments/1148343796644458566/1164965394998636574/375901556_827196845717339_1041275780904625271_n.jpg?ex=65452192&is=6532ac92&hm=60fd5f61e09ddd832cb7695f62576875d18dcc152d8f02b17a588f8a968fdf62&"
            }
          }
        ]
      }
  
      try {
        const response = await fetch(webhookUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
  
        if (response.ok) {
          Swal.fire({
            toast: true,
            icon: "success",
            title: "Solicitação enviada com sucesso!",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          $("#form_solicitacao").get(0).reset();
        } else {
          Swal.fire({
            toast: true,
            icon: "error",
            title: "Erro ao enviar a messagem!",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    enviarMensagem();
  };

  return (
    <form
      id="form_solicitacao"
      onSubmit={sendEmail}
      className="axil-contact-form"
    >
      <div className="form-group">
        <label>Nome</label>
        <input
          type="text"
          className="form-control"
          name="nome"
          placeholder="Seu nome"
          required
        />
      </div>
      <div className="form-group">
        <label>E-mail</label>
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder="exemplo@email.com"
          required
        />
      </div>
      <div className="form-group">
        <label>Telefone</label>
        <input
          id="tel"
          type="tel"
          className="form-control"
          name="telefone"
          placeholder="(00) 00000-0000"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Sobre</label>
        <textarea
          id="sobre"
          type="textarea"
          className="form-control"
          name="sobre"
          placeholder=""
          rows="3"
          required
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Enviar
        </button>
      </div>
    </form>
  );
};

export default FormContato;
