import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import SectionTitle from '../componentes/SectionTitulo';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import FaqData from '../json/faq.json';

const allData = FaqData;

const Faq = () => {
    return (
        <div name="parteContatos" className="section section-padding-equal bg-color-light faq-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4">
                        <AnimationOnScroll animateIn="fadeInLeft" animateOnce={true} delay={100}>
                            <SectionTitle
                                subtitle="FAQ"
                                title="Perguntas frequentes"
                                description="Não sabe por onde começar? Veja nossas perguntas e respostas para ter uma ideia melhor de como nossos serviços podem ajudar você."
                                textAlignment="heading-left"
                                textColor=""
                            />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-7 col-xl-8">
                        <AnimationOnScroll animateIn="fadeInRight" animateOnce={true} delay={100}>
                            <div className="faq-accordion">
                                <Accordion defaultActiveKey="1">
                                    {allData.map((data) => (
                                        <Accordion.Item eventKey={data.id} key={data.id}>
                                            <Accordion.Header><span>{`${data.id}.`}</span>{data.title}</Accordion.Header>
                                            <Accordion.Body>
                                                <p dangerouslySetInnerHTML={{ __html: data.body }}></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-35.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="line" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/banner/ajuda2.svg"} alt="Poses" /></li>
            </ul>
        </div>
    )
}

export default Faq;