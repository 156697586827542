import React from 'react';

import SectionTitulo from '../componentes/SectionTitulo';

import Header from '../componentes/header';
import SEO from '../componentes/SEO';
import Tema from '../componentes/tema';
// import BtnAcessibilidade from '../acoes/btn_acessibilidade';
import VLibras from "@djpfs/react-vlibras";

import BannerInico from '../componentes/BannerInicio';
import Catalogo from '../componentes/Catalogo';

import Faq from '../componentes/Faq';
import Sobre from '../componentes/Sobre';
import Footer from '../componentes/Footer';


const Inicio = () => {

    return (
        <>
        <SEO />
        <Tema />
        <VLibras />
        {/* <BtnAcessibilidade /> */}
        <main className="main-wrapper">
            <Header />
            <BannerInico />
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                    <SectionTitulo 
                        subtitle="O que nós podemos fazer por você"
                        title="Soluções terceirizadas para seus negócios"
                        description="Oferecemos soluções terceirizadas para otimizar seus negócios. Com serviços personalizados de limpeza, conservação, administração e logística, nossa equipe está pronta para atender às suas necessidades e impulsionar seu sucesso."
                        textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className='row'>
                        <Catalogo colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="6" />
                    </div>
                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>
            <Sobre />
            <Faq />
        <Footer parentClass="" />
        </main>
        </>
    )
}

export default Inicio;

