import React from 'react';
import { Link } from 'react-scroll';

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to="parteInicio" smooth={true} duration={1000} className='cursor-pointer'>Início</Link></li>
                <li><Link to="parteContatos" smooth={true} duration={1000} className='cursor-pointer'>Contatos</Link></li>
                <li><a className='botaorh' href="http://201.3.107.65:8080/rubiweb/delta.htm">RH 24H</a></li>
                <li><Link to="parteForm" smooth={true} duration={1000} className='cursor-pointer'>Sobre</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;
