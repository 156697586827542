import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import ScrollToTop from './componentes/ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css';

import Inicio from './paginas/inicio';
import ErrorPage from './paginas/404';

import './assets/scss/app.scss';


console.log('%cNão cole nada aqui!', 'font-size: 50px; color: red; font-weight: bold;');

const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={"/"} element={<Inicio />}/>
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
