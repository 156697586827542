import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
// import { Link } from 'react-router-dom';
// import Map from './Mapa';

// import BotaoOrcamento from '../acoes/btn_orcamento';


const BannerInicio = () => {
    return (
        <div name="parteInicio" className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="title">Terceirize seus serviços com excelência.</h1>
                                <span className="subtitle">Bem-vindo à nossa empresa! Terceirizamos diversos serviços para sua empresa, desde limpeza e conservação até apoio adminstrativo à logística. Conte conosco para garantir a excelência e satisfação em seus negócios.</span>
                                {/* <BotaoOrcamento></BotaoOrcamento> */}
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="fadeInLeft" animateOnce={true}  delay={130}>
                            <div className="large-thumb-1">
                                <img src={process.env.PUBLIC_URL + "/images/BannerInicio.png"} alt=""/>
                            </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-4">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"} alt="Bubble" />
                </li>
                <li className="shape shape-7">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerInicio;