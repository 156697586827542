import React from 'react';
import FormContato from './FormContato';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Sobre = () => {
    return (
        <section name="parteForm" className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                    <AnimationOnScroll animateIn="fadeInLeft" animateOnce={true} delay={100}>
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">Sobre nós</span>
                                <h2 className="title mb--40">Soluções pontuais garantidas</h2>
                                <p>A DeltaLimp é uma empresa de serviços terceirizados com mais de 27 anos de experiência no mercado. Nossa equipe altamente capacitada e experiente se dedica a fornecer soluções personalizadas e eficientes para atender às necessidades de cada cliente.</p>
                            </div>
                        </div>
                    </AnimationOnScroll>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <AnimationOnScroll animateIn="fadeInRight" animateOnce={true} delay={100}>
                        <div className="contact-form-box">
                            <h3 className="title">Descubra por que somos a escolha certa para o seu negócio</h3>
                           <FormContato />
                        </div>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default Sobre;