import React from "react";

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. DeltaLimp Serviços Terceirizados Ltda.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
